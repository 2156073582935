<template>
    <div>
        <div v-if="user && user.id" style="color:rgba(41,41,41,1);background:rgba(255,255,255,1);overflow: auto;margin:0 30px;padding:30px;text-align:left;">
            <el-form :model="user" :rules="rules" ref="ruleForm" label-width="100px" style="max-width: 350px;">
                <el-form-item label="员工编号" prop="member_id">
                    <el-input v-model="user.member_id" :disabled="!editing"></el-input>
                </el-form-item>
                <el-form-item label="姓名" prop="name">
                    <el-input v-model="user.name" :disabled="!editing || user.cert_stat==2"></el-input>
                </el-form-item>
                <el-form-item label="性别">
                    <el-radio-group v-model="user.gender" :disabled="!editing  || user.cert_stat==2">
                        <el-radio :label="1">男</el-radio>
                        <el-radio :label="2">女</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="头像" prop="avatar">
                    <el-upload class="comp-cert-uploader" action="xx" :show-file-list="false" :accept="uploadAccept"
                        :http-request="uploadImg" :before-upload="beforeUpload" :disabled="!editing">
                        <img v-if="user.avatar" :src="imgUrl+user.avatar" class="comp-cert-logo">
                        <i v-else class="el-icon-plus comp-cert-logo-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="手机" prop="mobile">
                    <el-input v-if="user.ori_mobile" v-model="user.ori_mobile" :disabled="!editing"></el-input>
                    <el-input v-else v-model="user.mobile" :disabled="!editing"></el-input>
                </el-form-item>
                <el-form-item label="座机" prop="tel">
                    <el-input v-model="user.tel" :disabled="!editing"></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="mail">
                    <el-input v-model="user.mail" :disabled="!editing"></el-input>
                </el-form-item>
                <el-form-item label="部门" prop="dept_ids">
                    <el-cascader :options="depts" :show-all-levels="false"
                        :props="{multiple:true,emitPath:false,value:'id',label:'name'}" v-model="user.dept_ids" :disabled="!editing">
                    </el-cascader>
                </el-form-item>
                <el-form-item label="职务" prop="role">
                    <el-select v-model="user.role_ids" multiple placeholder="请选择" :disabled="!editing">
                        <el-option v-for="role in roles" :key="role.id" :label="role.name" :value="role.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="editing">
                    <el-button type="primary" @click="save">保存</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
    import { IMG_URL_PRE } from '@/config'
    import { uploadFile } from '@/service/common';
    import { getUserInfoByIds, getRolesByCompId,modUserInfo } from '@/service/account';
    import { getDeptsByCompId } from '@/service/company';
    import { fetchTree } from '@/util'
    export default {
        props:{
            id:Number,
            editing:Boolean,
            popTitle:String,
        },
        data() {
            return {
                imgUrl: IMG_URL_PRE,
                uploadAccept: 'image/jpeg,image/gif,image/png',
                depts: [],
                roles: [],
                user: {},
                rules: {
                    name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
                    member_id: [{ required: true, message: '请输入员工编号', trigger: 'blur' }],
                    dept_ids: [{ required: true, message: '请选择部门', trigger: 'blur' }]
                },
            }
        },
        watch:{
            popTitle:{
                handler() {
                    this.getUser();
                },
                deep: true
            }
        },
        async created() {
            this.getUser();
            let _depts = await getDeptsByCompId(this.$store.state.loginUser.comp.id);
            this.depts = fetchTree(Object.values(_depts), 0, null);
            this.roles = (await getRolesByCompId(this.$store.state.loginUser.comp.id)).list;
        },
        methods: {
            getUser() {
                getUserInfoByIds(this.$store.state.loginUser.comp.id, [this.id]).then(rst => {
                    console.log("rst",rst)
                    if (rst && rst.length>0) {
                        let _user = rst[0];
                        // 处理组织/角色
                        if (_user.depts && _user.depts.length>0) {
                            _user.dept_ids=_user.depts.reduce((pre,cur)=>{
                                pre.push(cur.id);
                                return pre;
                            },[]);
                        }
                        if (_user.roles && _user.roles.length>0) {
                            _user.role_ids=_user.roles.reduce((pre,cur)=>{
                                pre.push(cur.id);
                                return pre;
                            },[]);
                        }
                        this.user = _user;
                        console.log(this.user);
                    }
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            save() {
                this.$refs['ruleForm'].validate((valid) => {
                    if(valid){
                        console.log(this.user);
                        this.user.user_id=this.user.id;
                        modUserInfo(this.user).then(()=>{
                            this.$emit('onSuccess');
                        }).catch(err => {
                            this.$message.error(err.message);
                        });
                    }
                });
            },
            uploadImg(item) {
                uploadFile(item.file).then(url => {
                    this.user.avatar = url;
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            beforeUpload(file) {
                const isLt2M = file.size / 1024 / 1024 < 10;
                if (!isLt2M) {
                    this.$message.error('上传LOGO图片大小不能超过 10MB!');
                }
                return isLt2M;
            },
        },
    }
</script>
<style>
    .comp-cert-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        width: 178px;
        height: 178px;
    }

    .comp-cert-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .comp-cert-logo-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .comp-cert-logo {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
<style scoped>

</style>