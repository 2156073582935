import _ from 'lodash'
import { IMG_URL_PRE } from '@/config';
function validField(from,field){
    return new Promise((resolve,reject)=>{
        from.validateField([field],(msg)=>{
            if(msg) return reject(msg);
            return resolve();
        });
    });
}

/**
 * 异步验证表单字段
 * @param {*} from 表单实例
 * @param {*} fields 字段名称数组
 */
async function validFields(from,fields){
    for(let field of fields){
        await validField(from,field);
    }
}

function fetchTree(list,pid,map){
    let _default={
        id:{src:'id',dest:'id'},
        parent:'pid',
        name:{src:'name',dest:'name'},
        children:'children'
    };
    if(map) _.merge(_default,map);
    let nodes=[];
    for(let item of list){
        if(item[_default.parent]==pid){
            let node = {};
            node[_default.id.dest]=item[_default.id.src];
            node[_default.parent]=pid;
            node[_default.name.dest]=item[_default.name.src];
            node[_default.children]=fetchTree(list,item[_default.id.src],map);
            node.data = item;
            nodes.push(node);
        }
    }
    return nodes.length>0 ? nodes:null;
}
function imgLayout(items) {
    items = items.reduce((pre,cur)=>{
        pre.push(cur.replace(IMG_URL_PRE,''));
        return pre;
    },[]);
    return items;
}
function findItem(items,id) {
    let obj = items.find((e)=> {
        return e.id == id;
    })
    return obj;
}
export {validFields,fetchTree,imgLayout,findItem}